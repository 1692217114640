<template>
  <div class="task-item">
    <div ref="taskCard" :data-task-id="task.id" class="custom-font task-handle">
      <b-row :class="'title-gap'">
        <b-col cols="12">
          <div
              v-on:click="$emit('show-sidebar',task)"
              v-b-toggle.sidebar-right
          >

           <div>
             <b-badge v-if="task?.overdue" variant="light-danger" class="mb-1">Over Due</b-badge>
           </div>

            <span class="task-item-title" :title="task?.title">
              {{ truncateTitle(task?.title) }}

            </span>
            <div v-if="isSubtask" class="mt-1 mb-1" :title="task?.task?.data?.title">

              <b-badge variant="light-warning">{{
                  truncateTitle(task?.task?.data?.title)
                }}</b-badge>
            </div>
            <span v-if="task?.observer" class="ml-1">
              <b-avatar
                  v-b-tooltip.hover.top="
                  `Observer: ${task?.observer?.data?.name} (${task?.observer?.data?.email})`
                "
                  :src="task?.observer?.data?.avatar === '' ? '/avatar.svg' : task?.observer?.data?.avatar"
                  size="26"
              />
            </span>
          </div>
        </b-col>

        <!-- <b-col >
        </b-col> -->


      </b-row>

      <b-row>

        <b-col cols="9">

          <div class="mt-1">
            <span
                v-for="(member, index) in task?.team?.data"
                :key="index"
            >
              <template v-if="index <= 2">
                <b-avatar
                    :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar"
                    size="26"
                /></template>
            </span>
            <span v-if="task?.team?.data.length > 3">
              + {{ task?.team?.data.length - 3 }} more
            </span>
            <b-button
                variant="outline-primary"
                class="btn-icon rounded-circle btn-sm ml-1"
                @click="$emit('show-member', task)"
            >
              <feather-icon icon="UserPlusIcon"/>
            </b-button>
          </div>

        </b-col>

        <b-col cols="3">
          <div>
            <b-dropdown
                variant="link"
                no-caret
                :id="`task-d-down-${task?.id}`"
                :ref="`task-d-down-${task?.id}`"
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25 mt-1"
                />
              </template>

<!--              <template>-->
<!--                <b-dropdown-item-->
<!--                    @click="$emit('next-phase', task)"-->
<!--                    v-if="phaseIndex !== phaseLength - 1"-->
<!--                >-->
<!--                  <feather-icon-->
<!--                      icon="ArrowRightCircleIcon"-->
<!--                      class="mr-50"-->
<!--                  ></feather-icon>-->
<!--                  <span>Next Phase</span>-->
<!--                </b-dropdown-item>-->
<!--              </template>-->

<!--              <template>-->
<!--                <b-dropdown-item-->
<!--                    v-if="phaseIndex !== 0 && (!phase?.is_final || $permissionAbility(MILESTONE_BOARD_UPDATE_TASK_FINAL_PHASE, permissions))"-->
<!--                    @click="$emit('previous-phase', task)"-->
<!--                >-->
<!--                  <feather-icon icon="ArrowLeftCircleIcon" class="mr-50"></feather-icon>-->
<!--                  <span>Previous Phase</span>-->
<!--                </b-dropdown-item>-->
<!--              </template>-->


              <template
                  v-if="
                        $permissionAbility(
                          MILESTONE_BOARD_TASK_DELETE,
                          permissions
                        )
                      "
              >
                <b-dropdown-item
                    @click="$emit('delete-task', task)"
                >
                  <feather-icon
                      icon="Trash2Icon"
                      class="mr-50 board-trash-icon"
                  ></feather-icon>
                  <span>Delete</span>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
        </b-col>

      </b-row>
    </div>

  </div>
</template>


<script>
import {
  BAvatar, BBadge,
  BButton,
  BCard,
  BCardSubTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BListGroupItem,
  BRow, VBToggle, VBTooltip
} from "bootstrap-vue";
import {mapGetters} from "vuex"
import {
  MILESTONE_BOARD_UPDATE_TASK_FINAL_PHASE,
  MILESTONE_BOARD_TASK_DELETE,
} from "@/helpers/permissionsConstant";
import Ripple from "vue-ripple-directive";

export default {
  name: "TaskCard.vue",
  props: {
    task: {
      type: Object,
      required: true
    },
    isSubtask: {
      type: Boolean,
      required: false,
      default: false
    },
    phase: {
      type: Object,
      required: true
    },
    phaseLength: {
      type: Number,
      required: true
    },
    phaseIndex: {
      type: Number,
      required: true
    }
  },
  components: {BBadge, BDropdownItem, BAvatar, BDropdown, BRow, BCol, BListGroupItem, BCardSubTitle, BCard, BButton},
  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions'
    }),
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      MILESTONE_BOARD_UPDATE_TASK_FINAL_PHASE,
      MILESTONE_BOARD_TASK_DELETE,
    }
  },
  methods: {
    truncateTitle(title) {
      const maxLength = 25; // Set your maximum length here
      if (title && title.length > maxLength) {
        return title.slice(0, maxLength) + "...";
      }
      return title;
    },
  }
}
</script>

<style scoped lang="scss">
.title-gap{
  margin-bottom: 15px;
}
.dark-layout {
  .task-item {
    background-color: #161d31 !important;
  }
  .task-item-title{
    color: white !important;
  }
}
</style>